// -----------------------------------//
// NAVIGATION WRAPPERS
// -----------------------------------//

nav#navholder {

    position: relative; z-index: 400; background: rgba(7, 19, 35,0.5); @extend .clearfix;
    > .wrapper { width: 990px; margin: 0 auto; }

}

// -----------------------------------//
// MAIN NAVIGATION
// -----------------------------------//

ul.nav {

	display: table; width: 990px; margin: 0; list-style: none;

	// Top Level List

	li {
	    margin: 0; padding: 0; background: none;
	    position: relative; display: table-cell; text-align: center; white-space: nowrap;
	    a {
	    	height: 50px; line-height: 50px; display: block; text-align: center;font-size: 18px;color: #fff;
	    }
	}
	> li:hover > a, > li > a:hover { box-shadow:inset 0 -5px 0 0 #fdbb30; text-decoration: none; }

	// 2nd Level List

	li {
		ul { position: absolute; left: -999em; margin: 0; padding: 0 20px 20px; background: none; opacity: 0;visibility: hidden;transition: (all 0.3s ease-in-out);}
		&:hover ul { left: auto; margin-left: -20px; opacity: 1;visibility: visible;transition: (all 0.3s ease-in-out);}
		&:active ul{transition: (opacity 0.1s ease-in-out);}
	}

	// 2nd Level List Items

	li li {
		float: none; display: block; padding: 0; height: auto; line-height: 35px;
		a { background: #fdbb30; height: 28px; line-height: 28px; padding: 3px 12px; min-width: 150px; float: none; top: auto; display: block; position: static; text-align: left; text-decoration: none;font-size: 13px;text-transform: none; color: #000;}
		&:hover, &:hover > a, a:hover, a:focus { background: #fff; color: #fdbb30; }
	}

	// Prepend 'more' arrow to parent list items

	li li a.parent:after { margin: -1px 0 0 10px; content: url('/i/design/lite/nav-right-arrow-black.png'); opacity: 1; line-height: 1; }

	// 3rd Level List

	ul ul { z-index: 505; }
	li:hover ul ul { left: 100%; top: 0; opacity: 0;visibility: hidden;transition: (all 0.3s ease-in-out);}
	ul li:hover ul { left: 100%; top: 0; opacity: 1;visibility: visible;transition: (all 0.3s ease-in-out);}

}
