@import url(https://fonts.googleapis.com/css?family=Lato:400,700,300italic,400italic);

// -----------------------------------//
// ELEMENTS COLOURS
// -----------------------------------//
$slideOut-panel-bg: #f7f7f7;

$link-bg-colour: #fff;
$link-bg-colour-hover: #ebebeb;
$links-colour: #444444;

$slide-click-colour: #444444;


/* Full container */
#slideOut {
	position: fixed;
	width: auto;
	height: auto;
	min-height: 250px;
	top: 50%;
	left: -250px;
	overflow: hidden;
	padding: 0px;
  z-index: 99999999;
}


/* Click to slide */
#slideClick {
	float: right;
	height: 100%;
	width: 70px;
	cursor: pointer;
	position: relative;
	background: #fdbb30;

	span{
		width: 160px;
		height: 70px;
		position: absolute;
		top: 50%;
		left: 65%;
		font-size: 25px;
		color: #fff;
		font-family: "Times New Roman", Times, serif;
		text-transform: uppercase;
		white-space: nowrap;
		transform: rotate(-90deg);
		margin-left: -73px;
		margin-top: -37px;
	}
}

/* Content links */
#slideContent {
	width: auto;
	height: 100%;
	padding: 0;
	margin: 0;
	float: left;
	color: #EFEFEF;
	text-align: center;
	background:  rgba(0, 45, 106, 0.6);
}


/* Box with links  */
ul.quick-links{
	width: 238px;
	display: block;
	padding: 1px;
	text-align: center;
	overflow: hidden;
	border-radius: 8px;

	li{
		background: none;
		margin: 0;
		padding: 0;
		display: block;


		a{
			background:  url(/i/design/arrow-quick-links.png) no-repeat left 15px center;
			color: #fff;
			font-size: 18px;
			display: block;
			text-align: left;
			padding: 5px 10px 5px 40px;
			font-weight: 300;
			transition: 1s;
			&:hover{
				color: #fdbb30;
				text-decoration: none;
				transition: 1s;
				font-weight: 700;
			}
		}
	}
}

@media only screen and (min-width: 750px) {

	#slideContent{ display: table !important; }
	ul.quick-links { vertical-align: middle; display: table-cell; }

}

/* Change the arrow  */
.open span::before{transform: rotate(-90deg) !important;}
.flip { display: none; }

@media only screen and (max-width: 750px) {
	#slideOut{
		position: relative;
		top: 44px;
		min-height: 0px;
		left: 0;
		padding: 5px 3% 0 2%;
		overflow: visible;
		height: auto !important;
		box-shadow: 0 0 0 1px rgba(0,0,0,0.08);
		border-radius: 0 0 10px 10px;
		margin: 0 5%;
		background: #002d6a;
	}

	#slideClick{
		display: none;
		span{
			transform: rotate(0);
			position: absolute;
			top: 0;
			left: 0;
			width: auto;
			height: auto;
			margin-left: 0;
			margin-top: 0;
			position: relative;
		}
	}

	#slideContent{
		float: none;
	}

	ul.quick-links{
		width: 100%;
		li{
			a{
				padding: 6px 0 6px 20%;
				font-size: 1.1em;
				background-position: 8% 10px;
			}
		}
	}


	.flip{
		z-index: 800;
		margin: 0;
		padding:0 0 5px 0;
		cursor: pointer;
		font-size: 16px;
		color: #fff;
		display: block;

		img{display: block; margin: 0 auto; transform: rotate(90deg);}
	}

	.open img{transform: rotate(-90deg);}
}
