.home-quotes { background: url(/i/design/quote-bg.png) no-repeat bottom center; width: 650px;  height: 230px; position: absolute; left: 0; bottom: 30px;

// rgba(0, 26, 63,0.6);

ul#bxslider {
  li { color: #fff; background: none; padding: 0; font-size: 20px; font-weight: 200; font-style: italic; text-align: left; margin: 45px 0 0 0; letter-spacing: 0.01em;
      span { display: block; font-weight: 700; color: #fdbb30; font-size: 18px; margin: 5px 0 0 0;}
    }
  }
}

.bx-viewport  { height: auto !important; }

.tagline-2 { position: absolute; left: 0; bottom: 270px;

  p { text-align: left; color: #fff; font-size: 45px; text-transform: uppercase; font-family: "Times New Roman", Times, serif; font-weight: bold; margin: 0; letter-spacing: 0.04em; line-height: 1.4;
    span { color: #fdbb30; }
    }

}

#slider {
    width: 100%;
    position: relative;
    height: 270px;
    margin: auto;
    background: rgba(0,0,0,0.3);

    .wrapper {  position: relative; }

}
