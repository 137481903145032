//iPad basic
@media only screen and (max-width: 1024px) {

	// 	//Wrapper
	// .wrapper{width: 950px;}
	//
	// //Nav wrapper
	// nav#navholder > .wrapper{width: 100%;}
	// ul.nav{width: 100%;}
	//
	//
	// //Mainholder
	// #mainholder{width: 950px;}
	//
	// //Contents
	// .Content1Column{width: 950px;}

}

// Mobile Version

@media only screen and (max-width: 770px) {

	// Body

	body {
	 	min-width: 0;
	 	overflow-x: hidden;
	 	//font-size: 13px;
	}

	// Global

	img {
		max-width: 100%;
		height: auto;
	}
	table {
		max-width: 100%;
	}

	//Responsive tables
	table.tableborder{width: 100%; overflow: scroll; white-space: nowrap; table-layout: fixed;position: relative;
		&:before{content: "";width: 30px;height: 100%;position: absolute;top: 0;right: -1px;
			background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 100%);
			background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.2) 100%);
			background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.2) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#33000000',GradientType=1 );
		}
		tbody{width: 100%; float: left; white-space: normal; overflow-x: scroll;}
	}

	// Wrappers
	.wrapper {
		width: 100%;
	}

	// Calendar

	#calendar {
	    .fc-header td {
	        display: block;
	        width: 100%;
	    }
	    h2 {} .fc-button-content {
	        font-family: Arial;
	    }
	    .fc-header-right {
	        text-align: left;
	    }
	    .fc-header-center {
	        text-align: left;
	    }
	}

	// Forms

	form div input[type="text"], form div textarea, form span textarea, form div input[type="textarea"], form div input[type="password"] {
	    width: 200px;
	}
	form div span.button {
	    padding-left: 0;
	}

	//Search Form
	#FS{ display: none;}
	.translate { display: none; }
	.contact-us { display: none; }
	.header-twitter { display: none; }

	// Header
	header{
		.wrapper{padding: 0;}

	}

	img#logo {  margin: 80px auto 0; float: none; display: block;}

	//Slider
	#slider{ width: 100%;}

	.tagline-2 { right: 0; width: 100%; bottom: 50px;

		p { text-align: center; font-size: 30px;}
	}

	.home-quotes { display: none; }


	// Mainholder
	#mainholder{width: 100%;padding: 0;}

	#welcome { height: auto; width: 100%;
		&:before { display: none; }

		.welcome { width: 100%; float: none;
			h1 { text-align: center; width: 90%; margin: 10px auto;}
			p { text-align: center; width: 90%; margin: 12px auto;}
			.more { margin: 5% auto; }
		}

		.notices { width: 100%;  background: #fdbb30; float: none; overflow: hidden;
			h2 { margin: 20px auto 0; }
			.notice-scroll { width: 80%; margin: 5% auto; height: 300px;} 
		}
	}

	// Contents
	.Content1Column,
	.Content2Column,
	.Content3Column{width: 80%; padding: 5%; margin: 5%;}

	// Col Left and Right
	.ColumnLeft,
	.ColumnRight{width: 80%; padding: 5%; margin: 5%;}

	//Home page alert
	.modal-home{
		.message-modal{width: 90%; margin: 0 5%; left: 0; top: 20%; padding: 0;
			.modal-content{
				.modal-text{padding: 3%;}
				a.close-modal{position: absolute; top: 3%; right: 3%; background: #fff; padding: 3px; border-radius: 0px 0px 0px 7px;}
			}
		}
	}

	#school-feeds { height: auto; width: 80%; padding: 0 10%}

	#SideHeadingNews { width: 100%; float: none; overflow: hidden; margin: 5% auto;}

	#SideHeadingDiary { width: 100%; float: none; overflow: hidden; margin: 5% auto;}

	.twitter-feed { position: inherit !important; height: auto;}

	// Footer
	footer{ height: auto;
		.wrapper{width: 90%;padding: 5%;
			h2 { text-align: center; margin: 10px auto; }
			p { text-align: center; margin: 10px auto;
				&:before { display: none;}
			}
			a { text-align: center; margin: 10px auto;
				&:before { display: none;}
			}
			br { display: none; }
		}
	}

	.twitter-footer { position: inherit !important; right: 0;}

	.awards-bar { display: none; }

	#map { display: none; }

	.sub-footer  { height: auto; line-height: normal;
	p { text-align: center; margin: 5px auto; float: none;}
	#credit { float: none; }
}
}
