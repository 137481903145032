.twitter-feed   { position: absolute; right: 0; left: 0; margin: auto; width: 310px; height: 305px;
	.more { background: #98c3fc; }
}

.twitter-wide { width: 310px; background: #fff; padding: 20px 0;}

// -----------------------------------//
// TWITTER PANEL BESPOKE
// -----------------------------------//
#twitterholder  { width: 90%; overflow: hidden; margin: auto;  overflow-y: scroll; height: 270px;

	ul li { padding: 0; margin: 10px auto; background: url(/i/design/diary-list.png) no-repeat bottom center; min-height: 150px; height: auto; width: 90%; overflow: hidden;
		.user img { border-radius: 50%; height: 36px; width: 36px; float: left; margin-right: 15px; display: none;}

		.user {
			span{
				&:first-of-type{float: left; }
				&:nth-of-type(2){ text-transform: none !important; font-size: 12px !important; font-weight: 500 !important; width: 70% !important; color: #071323 !important;}
				&:nth-of-type(3){display: none;}
				&:last-of-type{width: 100%; font-size: 12px; color: #071323; display: inline-block !important; text-align: left; font-weight: bold; float: left;}
			}
		}
	 }

	 p.interact { display: none;}
	 p.tweet { color: #333; text-align: left;display: block;font-size: 13px; margin: 10px auto; float: left;
		 a { color: #fdbb30; }
	 }
	 p.timePosted  { text-align: left; font-size: 13px; float: left;
		 a { color: #98c3fc; font-weight: 700;}
	 }
	 .media img { width: 100%; height: auto; padding: 10px 0;}

}

// Hide verified badge
span[data-scribe="element:verified_badge"] { display: none; }

// -----------------------------------//
// SCROLLBAR
// -----------------------------------//

.style-4::-webkit-scrollbar-track { 	background-color: #f0f0f0;}

.style-4::-webkit-scrollbar {	width: 7px;	background-color: #f0f0f0; }

.style-4::-webkit-scrollbar-thumb {	background-color: #002d6a;}
