@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');

// -----------------------------------//
// GLOBAL STYLES
// -----------------------------------//

// BODY
body                {color: #343434; font-size: 17px; font-family: 'Open Sans', sans-serif; text-align: center; line-height: 1.528571429;padding: 0px; margin: 0;

											// 	background: url(/i/design/design.jpg) center top no-repeat; opacity: .5;

 }

// LINKS
body a              {color: #5387cd; text-decoration: none;}
body a:hover, body a:focus {text-decoration: underline;}

// LINKED IMAGES
a img, img a, img, p a img 	{border: 0; border-bottom: 0; text-decoration: none; vertical-align: middle; }
a img:hover					{opacity: 0.8}

// HEADINGS
h1,h2,h3,h4,h5,h6	{font-weight: 500; font-family: "Times New Roman", Times, serif;}
h1					{font-size: 40px; margin: 0 0 20px 0; color: #253672; line-height:1.2; }
h2					{font-size: 26px; margin: 25px 0 20px 0; color: #5387cd; line-height:1.3; }
h3					{font-size: 18px; margin: 25px 0 10px 0; padding: 5px 10px; background: #002d6a;color: #fff; font-weight: 700;}
h4					{@extend h3; background-position: 10px center; background-repeat: no-repeat; padding-left: 30px; }
h4.open 			{background-image: url(/i/design/expanding-faq/minus-white.png); }
h4.closed 			{background-image: url(/i/design/expanding-faq/plus-white.png); }
h5 					{font-size: 12px; }
h6, small 			{font-size: 10px; }
h1+h2				{margin-top:0; border:0; padding:0; }
h1+p, h2+p, h3+p	{margin-top:0; }

// PARAGRAPHS AND LIST ITEMS
p					{margin: 12px 0; padding: 0; }
li					{margin: 2px 0; padding: 0 0 0 23px; background-image: url(/i/icons/bullet_black.png); background-repeat: no-repeat; }

// FORMS
form 					{margin:0; padding:0; @extend .clearfix; }
fieldset 				{border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }
legend 					{border: 0; padding: 0; white-space: normal; *margin-left: -7px; }
button, html input[type="button"], input[type="reset"], input[type="submit"] {-webkit-appearance: button; cursor: pointer; *overflow: visible; /* 4 */ }
button[disabled], html input[disabled] {cursor: default; }
input[type="checkbox"], input[type="radio"] {box-sizing: border-box; padding: 0; *height: 13px; *width: 13px; }
input[type="search"] 	{-webkit-appearance: textfield; box-sizing: content-box; }
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
button::-moz-focus-inner, input::-moz-focus-inner {border: 0; padding: 0; }
textarea 				{resize: vertical; overflow: auto; vertical-align: top; }
label 					{float: left; margin: 10px 0; width: 90px; display: inline;}
form .formfield 	    {margin: 10px; float: left; display: inline; width: 200px; border: 1px solid #CCC; padding: 2px; }
form div			    {margin: 15px 0; display: block; clear: left; vertical-align: top; }
form div input[type="text"], form div textarea, form span textarea, form div input[type="textarea"], form div input[type="password"] {border: 1px solid #CCC; padding: 4px; width: 300px; margin: 0; background-color: #EFEFEF;}
input[type="text"]:focus, form div textarea:focus, form span textarea:focus, form div input[type="textarea"]:focus, form div input[type="password"]:focus {border-color: #000; }
form div span.label label, form div span.label,span.spacer,span.multiple span {width: 180px; float: left; margin: 0 0 0 0; }
form div span.multiple  {float: left; }
form div span.button    {padding-left: 180px; background: transparent; border: 0; }
form div span.button input	{background: #666; color:#FFF; margin: 0; padding: 8px 10px; font-weight:bold; border: 0; }
form div span.button input:hover {background: #888; }
form div.clear 			{clear: both; padding-top: 5px; }

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper            {margin: 0 auto; text-align: center; }
.wrapper 			{width: 990px; margin: 0 auto; height: 100%; @extend .clearfix; }

// -----------------------------------//
// HEADER
// -----------------------------------//

header              { min-height: 180px; background: rgba(0, 45, 106,0.5);  }
header > .wrapper   {padding: 0; position: relative; z-index: 60; }

header h1 	        {margin: 0;  }


#logobar{text-align: left; overflow: hidden;}
img#logo{ margin: 20px auto 0; display: block;}

.contact-us { position: absolute; top: 88px; right: 0; height: 34px; width: 140px; display: block; background: url(/i/design/contact-bg.png) no-repeat; transition: 1s;
	&:hover { transition: 1s; transform: scale(1.1); }
}

.header-twitter			{ position: absolute; top: 41px; right: 109px; width: 33px; height: 27px; background: url(/i/design/twitter-header.png) no-repeat;  transition: 1s;
	&:hover { transition: 1s; transform: scale(1.1); }
}

.tagline  {
	p  {color: #fff; text-transform: uppercase;  text-align: center; font-size: 20px; font-weight: 300; letter-spacing: 0.1em; margin: 17px 0 7px 0;}
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

#FS{position: absolute;right: 0px; top: 40px; z-index: 999;}
#FS input {outline: none;color: #fff;}
#FS input[type=search] {-webkit-appearance: textfield;-webkit-box-sizing: content-box;font-family: inherit;font-size: 100%;}
#FS input::-webkit-search-decoration,
#FS input::-webkit-search-cancel-button {display: none;}

#FS input[type=search] {color: transparent;cursor: pointer;background: url(/i/design/search-ic.png) no-repeat 0 center;padding:0px;width: 29px;height: 29px; transition: all .5s;}

#FS input[type=search]:focus {width: 180px;background-color: #fff; padding: 10px 0 10px 10px;color: #000;cursor: auto;font-weight: 400;}

#FS input[type=search]:focus:-moz-placeholder {color: #fff;font-weight: 300;-webkit-transition: .3s ease-out; transition: .3s ease-out;}
#FS input[type=search]:focus::-webkit-input-placeholder {color: #fff;font-weight: 300;-webkit-transition: .3s ease-out; transition: .3s ease-out;}
#FS input:-moz-placeholder {color: transparent;-webkit-transition: .3s ease-out; transition: .3s ease-out;}
#FS input::-webkit-input-placeholder {color: transparent;-webkit-transition: .3s ease-out; transition: .3s ease-out;}


// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//
$search-highlight-background-color: lightblue;
$search-highlight-color: darkblue;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box{display: inline-block; background: #f1f1f1; padding: 5px 15px; font-size: 16px; border-radius: 4px; border: 1px solid #ccc; text-transform: uppercase;}
.search-numbers{text-transform: uppercase; font-weight: bold; font-size: 12px;
	span{background: #f1f1f1; padding: 3px 8px; margin: 0 3px;border-radius: 2px; border: 1px solid #ccc; }
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element   { opacity: 0;}

.translate { position: absolute; right: 55px; top: 40px; width: 30px; height: 30px; background: url(/i/design/translate-ic.png) no-repeat; overflow: hidden;}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

body.body_8533 #mainholder {  padding: 0; background: none;}

#mainholder         { width: 100%; margin: 0px auto; padding: 30px 0 30px 0; background: #fff; clear: both; text-align: left; @extend .clearfix; }


// -----------------------------------//
// SCROLLBAR
// -----------------------------------//

.style-4::-webkit-scrollbar-track { 	background-color: #002d6a;}

.style-4::-webkit-scrollbar {	width: 10px;	background-color: #fff; }

.style-4::-webkit-scrollbar-thumb {	background-color: #fff;}


#welcome 					{ width: 100%; background: #fff; height: 645px; position: relative; z-index: 9; overflow: hidden;
&:before { content: ''; position: absolute; right: 0; top: 0; height: 100%; width: 42%; background: #fdbb30; z-index: -1;}

	.notices { float: right; width: 310px;

		.notice-scroll { height: 420px; overflow-y: scroll;}

		h2 { color: #fff; text-transform: uppercase; font-size: 28px; font-weight: 600; text-align: center; margin: 90px 0 40px 0;}

		p { font-size: 16px; color: #3a3a3a; letter-spacing: 0.1em; width: 90%; margin: 20px 0;}

	}

	.welcome { width: 615px; float: left;
		h1 { color: #002d6a; text-transform: uppercase; margin: 36px 0 25px 0; font-weight: 900;
			span { color: #fdbb30; font-size: 20px; font-family: 'Open Sans', sans-serif; letter-spacing: 0.03em; font-weight: normal;}
		}

		p { color: #071323; font-size: 18px; letter-spacing: 0.1em; line-height: 1.85;}
		p.intro  { color: #002d6a; font-weight: 700; }
		p.signature { font-weight: 700; }
		.more { background: #002d6a; display: block;  width: 195px; text-align: center; color: #fff; font-weight: 700; height: 38px; line-height: 38px; font-size: 14px; text-transform: uppercase; transition: 1s; margin: 35px 0 0 0;
			&:hover { transition: 1s; border-radius: 40px; text-decoration: none}
		}
	}

}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column     {width: 930px; margin: 0; padding: 30px; float: left; min-height:350px; text-align:left; background: #f6f6f6;
	img{max-width:100%; height:auto; }
  h1 { text-transform: uppercase; }
  h1,h2 { font-weight: 700; }
}

//Content 2
.Content2Column     {width: 675px; margin: 0; padding: 30px; float: left; min-height:350px; text-align:left; background: #f6f6f6;
	img{max-width:100%; height:auto; }
  h1 { text-transform: uppercase; }
  h1,h2 { font-weight: 700; }
}

//Content 3
.Content3Column     {width: 430px; margin: 0; padding: 0; float: left; min-height:350px; text-align:left;
	img{max-width:100%; height:auto; }
}

.HomeAlert .Content1Column { float: none; margin: 0 auto;}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft			{width: 310px; margin: 0; padding: 0 0 0 0; min-height:250px; text-align:left; float: left; }
.ColumnRight		{width: 230px; margin: 0; padding: 0 0 0 0; min-height:250px; text-align:left; float: right; }

.ColumnLeft ul, .ColumnRight ul {margin: 0; padding: 0; list-style: none; }
.ColumnLeft nav ul a:link, .ColumnRight nav ul a:visited, .ColumnRight nav ul a:link, .ColumnRight nav ul a:visited {display: block; }
.ColumnLeft li 		        	{border-bottom: 1px solid #EEE; } // Hack to fix IE/Win's broken rendering of block-level anchors in lists
html > body .ColumnLeft li    	{border-bottom: none; } // Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading            	  { margin: 30px 0 0 0; padding: 0;  text-align: center;}
.SideHeading:first-child 	  { margin-top: 0; }

// Heading
.SideHeading h2         	  { display: block;text-align: center;font-size: 40px; padding: 47px 0 38px 0;margin: 0; text-transform: uppercase; font-weight: 800; color: #fff;}

// List
ul.SideList             	  { margin:0 0 0 0; padding: 0; border: 0; list-style: none; text-align: left;}
ul.SideList li         		  { margin:0 0 0 0; padding: 0; background: none;}

ul.SideList li a       		  { margin: 0; padding: 5px 10px; color: #333; transition: 1s; font-weight: normal;display: block; }
ul.SideList li a:hover  	  {   transition: 1s; text-decoration: none;}
ul.SideList li a.child        { background: url(/i/design/lite/bg_nav_li_a_child.png) left 30px center no-repeat; padding-left: 50px !important;
	&:hover{ }
}


// Date and Title
ul.SideList li p        { margin: 0; padding: 0; }
ul.SideList p.date      { float: left; width: 85px; height: 85px; text-align: center;background: #000;color: #fff;text-transform: uppercase;font-size: 18px;line-height: 130%;	font-family: "Times New Roman", Times, serif; font-weight: bold;
	span{display: block;font-size: 38px;margin: 20px 0 5px 0;}
}
ul.SideList p.title     { float: right; width: 65%;font-size: 12px; color: #071323;}

// More links
.SideHeading a.more     {display: inline-block;font-size: 14px;padding: 0px; transition: 1s; font-weight: bold; text-transform: uppercase; width: 140px;  height: 38px; line-height: 38px; color: #fff; margin: 30px 0 0 0;
	&:hover { transition: 1s; text-decoration: none; border-radius: 40px; }
}


#school-feeds				{ width: 100%; height: 570px; background: url(/i/design/school-feed-bg.jpg) no-repeat center; background-size: cover; overflow: hidden;}


//Diary
.ColumnRight  > #SideHeadingDiary { width: 100%; float: none; background: url(/i/design/news-bg.jpg) no-repeat; background-size: cover; margin: 60px 0 0 0;
  h2 { background: #002d6a; font-size: 25px; height: 50px; line-height: 50px; padding: 0; font-weight: normal; font-family: 'Open Sans', sans-serif; text-transform: capitalize; font-weight: 300;}
  ul.SideList {
    p.date { height: 50px; width: 50px; border-radius: 50%; font-size: 12px; font-weight: normal; background: rgba(0, 45, 106,0.6); box-shadow: 0 0 0 2px rgb(255, 248, 234); font-family: 'Open Sans', sans-serif;
      span { font-size: 25px; font-weight: 600; margin: 10px 0 5px 0;}
    }
    p.title { color: #fff; height: auto; line-height: normal; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; margin: 0px 0 0 0; font-size: 16px;}
    li a { background: none; height: auto;}
  }
  .more { background: #002d6a; width: 50%; margin: 0;}
}

#SideHeadingDiary{width: 310px; float: right; margin: 0;

	ul.SideList {
		li {
			a { padding: 10px 10px 9px 10px; background: #fff url(/i/design/diary-list.png) no-repeat right bottom; height: 80px; overflow: hidden;
				&:hover { background-color: #002d6a; }
			}
		}
		p.date { background: #002d6a; }
		p.title { height: 80px; line-height: 80px;}
	}
	a.more { background: #002d6a;   float: left;
		&:nth-of-type(2) { float: right; }
	}
}


//News

.ColumnRight  > #SideHeadingNews { width: 100%; float: none; background: url(/i/design/news-bg.jpg) no-repeat; background-size: cover;
  h2 { background: #002d6a; font-size: 25px; height: 50px; line-height: 50px; padding: 0; font-weight: normal; font-family: 'Open Sans', sans-serif; text-transform: capitalize; font-weight: 300;}
  ul.SideList {
    p.date { height: 50px; width: 50px; border-radius: 50%; font-size: 12px; font-weight: normal; background: rgba(0, 45, 106,0.6); box-shadow: 0 0 0 2px rgb(255, 248, 234); font-family: 'Open Sans', sans-serif;
      span { font-size: 25px; font-weight: 600; margin: 10px 0 5px 0;}
    }
    p.title { color: #fff; height: auto; line-height: normal; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; margin: 0px 0 0 0; font-size: 16px;}
    li a { background: none; height: auto;}
  }
  .more { background: #002d6a; width: 50%; margin: 0;}
}

#SideHeadingNews{ width: 310px; float: left;

	ul.SideList {
		li {
			a { padding: 10px 10px 9px 10px; background: #fff url(/i/design/news-list.png) no-repeat right bottom; height: 80px; overflow: hidden;
				&:hover { background-color: #fdbb30; }
			}
		}
		p.date { background: #fdbb30; }
		p.title { height: 80px; line-height: 80px;}
	}
	a.more { background: #fdbb30;   float: left;
		&:nth-of-type(2) { float: right; }
	}
}



//Related pages
#SideHeadingPages{  background: url(/i/design/pages-bg.jpg) no-repeat; background-size: cover;

  h2 { background: #fdbb30; font-size: 25px; height: 50px; line-height: 50px; padding: 0; font-weight: normal; box-shadow: 0 1px 0 0 #002d6a; font-family: 'Open Sans', sans-serif; text-transform: capitalize; font-weight: 300}

  ul.SideList { padding: 10px 0;
    li a { padding: 7px 20px; font-size: 15px; color: #343434;
      &:hover { background-color: rgba(255, 208, 107,0.8); }
    }
  }

}


// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home{background: rgba(0, 0, 0, .4);position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 8000;
	.message-modal{position: absolute;top: calc(50% - 300px);left: calc(50% - 465px - 40px - 10px);width: 930px;height: auto; margin: 0 30px; padding: 20px;background: rgba(255, 255, 255, .9);
		.modal-content{background: rgba(255, 255, 255, .9);background-position: -120px 50px ;box-shadow:inset 0 0 0 1px #fff;display: inline-block;width: 100%;height: 100%;text-align: center;
			.modal-text{display: block; padding: 45px;
				p{margin: 0;}
			}
			a.close-modal{position: absolute;top: 31px;right: 31px;}
		}
	}
}

.hide-modal{display: none !important;}



// -----------------------------------//
// FOOTER
// -----------------------------------//

footer                { clear: both; height: 310px; overflow: hidden; background: #002d6a;}
footer > .wrapper     {   text-align: left; position: relative; overflow: hidden;
	h2  {font-size: 40px; color: #fff; text-transform: uppercase; font-weight: 800; letter-spacing: 0.02em; margin: 46px 0 21px 114px;}
}
footer > .wrapper p   { font-size: 16px; position: relative; color: #fff;}
footer > .wrapper a   { font-size: 16px; position: relative; color: #fff; }

p.address { margin: 0 0 0 108px;
	&:before { content: ''; position: absolute; width: 21px; height: 29px; background: url(/i/design/location-ic.png) no-repeat; left: -40px;}
}

p.telephone { margin: 18px 0 0 105px;
	&:before { content: ''; position: absolute; width: 23px; height: 23px; background: url(/i/design/phone-ic.png) no-repeat; left: -40px; top: -2px;}
}

.email-footer	{ margin: 13px 0 -32px 108px; display: block; text-decoration: underline;
	&:first-of-type {
	&:before { content: ''; position: absolute; width: 24px; height: 19px; background: url(/i/design/mail-ic.png) no-repeat; left: -40px; top: 20px;}
}
}

.twitter-footer	{ background: url(/i/design/twitter-footer.png) no-repeat; height: 21px; width: 26px; display: block; position: absolute !important; left: 0; right: 111px; margin: auto; transition: 1s; z-index: 9999;
	&:hover { transition: 1s; transform: scale(1.2); }
}

#map 		{ position: absolute; right: 48px; bottom: 54px; width: 400px; height: 130px; overflow: hidden;  }

.get-directions { width: 220px; height: 38px; background: url(/i/design/get-directions.jpg) no-repeat; display: block; position: absolute !important; left: 0; right: 0; margin: auto; top: 0; bottom: 0; transition: 1s;
  &:hover { transition: 1s; cursor: pointer; transform: scale(1.3);}
}

.awards-bar { height: 80px; width: 100%; background: #fff;
	.wrapper { background: url(/i/design/awards-bg.jpg) no-repeat center;}
}

.sub-footer { height: 35px; width: 100%; line-height: 35px; background: #071323; overflow: hidden;
	p { font-size: 13px; color: #fff; float: left; margin: 0;}
	a { color: #fff; text-decoration: underline;}
	#credit        {  float: right; }
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #000;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p { font-style: italic;  font-family: 'Open Sans', sans-serif;}
$blockquote-background-color: #fff;
$blockquote-border-color: #EBEBEB;
$blockquote-color: #343434;
$blockquote-speech-mark-color: #002d6a;
$blockquote-font-size: 1em;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content { background: none; }


// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast         { position: fixed; top: 0; left: 0; width: 100%; height: 40px; border-bottom: 1px solid #D4C790; font-family: sans-serif; font-size: 12px; font-weight: bold; line-height: 40px; color: #6d6647; text-align: center; background: #FAF4AF; }
#browserblast a       { color: green; text-decoration: underline; }
.unsupported-browser  { margin-top: 41px; }

// -----------------------------------//
// FAQ SCRIPT
// -----------------------------------//

// h3.faq-open { background: #EFEFEF url(/i/design/expanding-faq/minus.png) right 12px center no-repeat; }
// h3.faq-closed { background: #EFEFEF url(/i/design/expanding-faq/plus.png) right 12px center no-repeat; }

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile      { width:100%; margin: 0; padding: 20px 0; background:#EFEFEF; border-top:1px solid #EBEBEB; border-bottom:1px solid #EBEBEB; text-align:center; color:#777; font-family: sans-serif; font-size: 12px; display:block; clear:both; }
#switch-mobile a    { text-decoration: underline; }
